.App {
  text-align: center;
}
 
.bg-cdq {
  /*background-color: #81bbb5;*/
  background-color: #f2f2f2;
}

.bg-cdq2 {
  /*background-color: #fbfcbc;*/
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}